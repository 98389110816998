import React from 'react';
import styled from 'styled-components';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContentWrapper onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <ModalContent>
          <ModalBody>
            {children}
          </ModalBody>
        </ModalContent>
      </ModalContentWrapper>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
`;

const ModalContentWrapper = styled.div`
  position: relative;
  background: #fff;
  width: 90%;
  max-width: 800px;
  height: 80%; 
  max-height: 600px;
  margin-bottom: 20px; 
  overflow: hidden; 
  border-radius: 10px;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto; 
`;

const CloseButton = styled.button`
  background-color: #f44336;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20%;
  border-radius: 5px;
  padding: 5px;
`;

const ModalBody = styled.div`
  padding-top: 40px; 
`;

export default Modal;
