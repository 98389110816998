import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import axios from 'axios';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import drugPlaceholderImg from '../images/drugplaceholderImg.png';
import headerImage from '../images/headerimage.jpg';
import Modal from '../components/Modal';
import Modalh from "../components/home/modal"

const CategoryTemplate = ({ data, pageContext }) => {
  const isBrowser = typeof window !== "undefined"
  const { category } = pageContext;
  const products = data.allDrugs.edges.map(edge => edge.node);
  const [cartCount, setCartCount] = useState(parseInt(isBrowser && window.localStorage.getItem('cartCount'), 10) || 0);
  const [filters, setFilters] = useState({
    location: [],
    wholesaler: [],
    formulation: [],
    genericName: []
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle filter change
  const handleFilterChange = (e) => {
    const { name, value, checked } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: checked
        ? [...prevFilters[name], value]
        : prevFilters[name].filter(v => v !== value)
    }));
  };

  // Handle clear filters
  const handleClearFilters = () => {
    setFilters({
      location: [],
      wholesaler: [],
      formulation: [],
      genericName: []
    });
  };

  // Filter products
  const filteredProducts = products.filter(product => {
    const locationMatch = filters.location.length === 0 || (product.wholesalers && product.wholesalers.some(wholesaler => wholesaler.wholesaler && wholesaler.wholesaler.locations && filters.location.some(loc => wholesaler.wholesaler.locations.includes(loc))));
    const wholesalerMatch = filters.wholesaler.length === 0 || (product.wholesalers && product.wholesalers.some(wholesaler => wholesaler.wholesaler && filters.wholesaler.includes(wholesaler.wholesaler.name)));
    const formulationMatch = filters.formulation.length === 0 || filters.formulation.includes(product.dosageFormName);
    const genericNameMatch = filters.genericName.length === 0 || filters.genericName.includes(product.innOfApi);
    return locationMatch && wholesalerMatch && formulationMatch && genericNameMatch;
  });

  const uniqueFormulations = Array.from(new Set(products.map(product => product.dosageFormName)));
  const uniqueGenericNames = Array.from(new Set(products.map(product => product.innOfApi)));
  const uniqueLocations = Array.from(new Set(products.flatMap(product => product.wholesalers ? product.wholesalers.flatMap(wholesaler => wholesaler.wholesaler && wholesaler.wholesaler.locations ? wholesaler.wholesaler.locations : []) : [])));
  const uniqueWholesalers = Array.from(new Set(products.flatMap(product => product.wholesalers ? product.wholesalers.map(wholesaler => wholesaler.wholesaler && wholesaler.wholesaler.name ? wholesaler.wholesaler.name : '') : [])));

  const uniqueCategories = Array.from(new Set(data.allCategories.edges.map(edge => edge.node.category)));

  const handleAddToCart = async (itemId) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      alert('You need to be logged in to add items to the cart. Please log in.');
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${process.env.GATSBY_API_URL}/cart/add`, {
        userId,
        drugId: itemId,
        quantity: 1,
      });
      console.log('Item added to cart:', response.data);

      const itemExists = response.data.items.find(i => i.drug.toString() === itemId);

      if (itemExists) {
        localStorage.setItem('cartCount', response.data.items.length);
        setCartCount(response.data.items.length);
      } else {
        const currentCartCount = parseInt(localStorage.getItem('cartCount'), 10) || 0;
        localStorage.setItem('cartCount', currentCartCount + 1);
        setCartCount(currentCartCount + 1);
      }

      const updatedCart = await axios.get(`${process.env.GATSBY_API_URL}/cart/${userId}`);
      console.log('Updated Cart:', updatedCart.data);

      window.dispatchEvent(new Event('cartUpdated'));

    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  const [isMobile, setIsMobile] = useState(isBrowser && window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Layout>
      <Modalh />
      <SEO title={`Products in ${category}`} />
      <HeaderImage src={headerImage} alt="Header Image" />
      <CategoryContainer>
        <CategoryScroll>
          {uniqueCategories.map(cat => (
            <CategoryButton key={cat}>
              <Link to={`/category/${cat}`}>
                {cat}
              </Link>
            </CategoryButton>
          ))}
        </CategoryScroll>
      </CategoryContainer>
      <CategoryTitle>Products in {category}</CategoryTitle>
      <Container>
        {isMobile ? (
          <>
            <FilterButton onClick={() => setIsModalOpen(true)}>Filter</FilterButton>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <FilterContainer>
                <FilterSection>
                  <h3>Location</h3>
                  {uniqueLocations.map(loc => (
                    <label key={loc}>
                      <input
                        type="checkbox"
                        name="location"
                        value={loc}
                        checked={filters.location.includes(loc)}
                        onChange={handleFilterChange}
                        className='mr-2'
                      />
                      {loc}
                    </label>
                  ))}
                </FilterSection>

                <FilterSection>
                  <h3>Wholesaler</h3>
                  {uniqueWholesalers.map(wholesaler => (
                    <label key={wholesaler}>
                      <input
                        type="checkbox"
                        name="wholesaler"
                        value={wholesaler}
                        checked={filters.wholesaler.includes(wholesaler)}
                        onChange={handleFilterChange}
                        className='mr-2'
                      />
                      {wholesaler}
                    </label>
                  ))}
                </FilterSection>

                <FilterSection>
                  <h3>Formulation</h3>
                  {uniqueFormulations.map(formulation => (
                    <label key={formulation}>
                      <input
                        type="checkbox"
                        name="formulation"
                        value={formulation}
                        checked={filters.formulation.includes(formulation)}
                        onChange={handleFilterChange}
                        className='mr-2'
                      />
                      {formulation}
                    </label>
                  ))}
                </FilterSection>

                <FilterSection>
                  <h3>Generic Name</h3>
                  {uniqueGenericNames.map(name => (
                    <label key={name}>
                      <input
                        type="checkbox"
                        name="genericName"
                        value={name}
                        checked={filters.genericName.includes(name)}
                        onChange={handleFilterChange}
                        className='mr-2'
                      />
                      {name}
                    </label>
                  ))}
                </FilterSection>

                <ClearFiltersButton onClick={handleClearFilters}>Clear Filters</ClearFiltersButton>
              </FilterContainer>
            </Modal>
          </>
        ) : (
          <FilterContainer>
            <FilterSection>
              <h3>Location</h3>
              {uniqueLocations.map(loc => (
                <label key={loc}>
                  <input
                    type="checkbox"
                    name="location"
                    value={loc}
                    checked={filters.location.includes(loc)}
                    onChange={handleFilterChange}
                    className='mr-2'
                  />
                  {loc}
                </label>
              ))}
            </FilterSection>

            <FilterSection>
              <h3>Wholesaler</h3>
              {uniqueWholesalers.map(wholesaler => (
                <label key={wholesaler}>
                  <input
                    type="checkbox"
                    name="wholesaler"
                    value={wholesaler}
                    checked={filters.wholesaler.includes(wholesaler)}
                    onChange={handleFilterChange}
                    className='mr-2'
                  />
                  {wholesaler}
                </label>
              ))}
            </FilterSection>

            <FilterSection>
              <h3>Formulation</h3>
              {uniqueFormulations.map(formulation => (
                <label key={formulation}>
                  <input
                    type="checkbox"
                    name="formulation"
                    value={formulation}
                    checked={filters.formulation.includes(formulation)}
                    onChange={handleFilterChange}
                    className='mr-2'
                  />
                  {formulation}
                </label>
              ))}
            </FilterSection>

            <FilterSection>
              <h3>Generic Name</h3>
              {uniqueGenericNames.map(name => (
                <label key={name}>
                  <input
                    type="checkbox"
                    name="genericName"
                    value={name}
                    checked={filters.genericName.includes(name)}
                    onChange={handleFilterChange}
                    className='mr-2'
                  />
                  {name}
                </label>
              ))}
            </FilterSection>

            <ClearFiltersButton onClick={handleClearFilters}>Clear Filters</ClearFiltersButton>
          </FilterContainer>
        )}

        <ProductGrid>
          {filteredProducts.map(product => (
            <ProductCardWrapper key={product.id}>
              <ProductImage src={drugPlaceholderImg} alt={product.productTradeName} />
              <ProductName>{product.productTradeName} - {product.atcCodeName}</ProductName>
              <ProductName>{product.dosageFormName}</ProductName>
              <ProductActions>
                {/* <button onClick={() => handleAddToCart(product.id)}>Add to Cart</button> */}
                <Link to={`/product/${product.productTradeName.replace(/ /g, "+").toLowerCase()}`} className='mb-2'>
                  <button>Buy</button>
                </Link>
                <Link to={`/product/${product.productTradeName.replace(/ /g, "+").toLowerCase()}`}>
                  <button>Details</button>
                </Link>
              </ProductActions>
            </ProductCardWrapper>
          ))}
        </ProductGrid>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($category: String!) {
    allDrugs(filter: { category: { eq: $category } }) {
      edges {
        node {
          id
          productTradeName
          atcCodeName
          dosageFormName
          category
          innOfApi
          wholesalers {
            wholesaler {
              name
              locations
            }
          }
        }
      }
    }
    allCategories: allDrugs {
      edges {
        node {
          category
        }
      }
    }
  }
`;

export default CategoryTemplate;


const HeaderImage = styled.img`
  width: 800px; 
  height: 300px; 
  object-fit: cover; 
  display: block;
  margin: 0 auto; 
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column; 
  
  
  @media (min-width: 800px) {
    flex-direction: row; 
    padding: 0 20px;
  }
`;

const CategoryContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const CategoryScroll = styled.div`
  display: inline-flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%; 
  padding: 10px 0; 

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #03989e; 
    border-radius: 10px; 
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #027a78; 
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; 
    border-radius: 10px; 
  }
`;

const CategoryButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 10px;
  text-align: center;
  margin-right: 40px; 

  a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }
`;

const CategoryTitle = styled.h1`
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;

const FilterButton = styled.button`
  background-color: #4169e1;
  color: #fff;
  border: none;
  padding: 8px 16px; 
  border-radius: 20px; 
  cursor: pointer;
  position: fixed;
  bottom: 20px; 
  display: flex;
  align-self: center; 
  justify-content: center;
  z-index: 1000;
  font-size: 20px; 
  width: auto; 

  &:hover {
    background-color: #037d7f;
  }

  @media (min-width: 900px) {
    display: none; 
  }
`;



const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ClearFiltersButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f44336;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  align-self: flex-start;

  &:hover {
    background-color: #c62828;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); 
    
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr; 
  }
`;

const ProductCardWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 24px;
  border: 1px solid #dddddd;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;
  max-height: 400px; 
  max-width: 300px;
  box-sizing: border-box; 
`;

const ProductImage = styled.img`
  width: 318.98px;
  height: 212.59px;
  object-fit: contain;
  border-radius: 12px;
`;

const ProductActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;

  button {
    padding: 8px 16px;
    background-color: #03989e;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 24px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #037d7f;
    }
  }

  a {
    text-decoration: none;
  }
`;

const ProductName = styled.h3`
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
